import * as Sentry from "@sentry/remix";
import {RemixBrowser, useLocation, useMatches} from "@remix-run/react";
import React, {startTransition, useEffect, useState} from "react";
import {hydrateRoot} from "react-dom/client";

import {ClientStyleContext} from './context'
import createEmotionCache, {defaultCache} from './createEmotionCache'
import {CacheProvider} from '@emotion/react';
import CustomerIoTracker from './components/CustomerIoTracker';

Sentry.init({
    dsn: "https://64bfb72c933b74d679eb0a2d0f70e454@o4504665265012736.ingest.us.sentry.io/4507679529959424",
    tracesSampleRate: 1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1,

    integrations: [Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches
      // eslint-disable-next-line import/namespace
    }), Sentry.replayIntegration()]
})

interface ClientCacheProviderProps {
  children: React.ReactNode;
}

function ClientCacheProvider({ children }: ClientCacheProviderProps) {
  const [cache, setCache] = useState(defaultCache)

  function reset() {
    setCache(createEmotionCache())
  }

  return (
    <ClientStyleContext.Provider value={{ reset }}>
      <CacheProvider value={cache}>{children}</CacheProvider>
    </ClientStyleContext.Provider>
  )
}

const hydrate = () => {
  startTransition(() => {
    hydrateRoot(
      document,
      <ClientCacheProvider>
        <RemixBrowser />
        <CustomerIoTracker/>
      </ClientCacheProvider>,
    )
  })
}

if (window.requestIdleCallback) {
  window.requestIdleCallback(hydrate)
} else {
  // Safari doesn't support requestIdleCallback
  // https://caniuse.com/requestidlecallback
  setTimeout(hydrate, 1)
}
